<template>
	<div>
		<a-navbar />
		<div id="main-content">
			<b-sidebar id="ProfileSidebar" :lazy="true" backdrop no-header>
				<a-subnav-profile></a-subnav-profile>
			</b-sidebar>
			<b-container>
				<b-row>
					<b-col class="d-none d-xl-block" xl="3">
						<a-subnav-profile></a-subnav-profile>
					</b-col>
					<b-col xl="9">
						<router-view/>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<a-footer/>
	</div>
</template>

<script>
export default {
	components: {
		'a-navbar': 				() => import('../default/a-navbar.vue'),
		'a-footer': 				() => import('../default/a-footer.vue'),
		'a-subnav-profile': () => import('./a-subnav-profile.vue')
	}
}
</script>

<style lang="scss" scoped>
#main-content {
	padding-bottom: 338px;

	@media screen and (max-width: 767px) {
		padding-bottom: 76vh;
	}
}
</style>